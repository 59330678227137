<template>
    <div>
        <v-card flat color="transparent" v-if="hasData">
            <v-img
                width="100%"
                height="15rem"
                class="rounded rounded-lg"
                :src="data.thumbnail"
                :lazy-src="data.thumbnail"
            ></v-img>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    @click="$emit('onOpenForm', { action: 'edit', data })"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                >
            </v-card-actions>
        </v-card>
        <div
            v-else
            style="width: 100%; height: 15rem"
            class="white rounded rounded-lg d-flex justify-center align-center c-pointer"
            @click="$emit('onOpenForm', { action: 'add' })"
        >
            <v-icon size="100">mdi-plus</v-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: "banner-preview-card",

    props: {
        data: Object,
    },

    computed: {
        hasData() {
            return !!this.data;
        },
    },
};
</script>
